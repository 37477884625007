import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "apiKey", "url", "js", "jsTimeout",
    "waitFor", "proxy", "country", "question", "preview", "response",
    "responseArea", "language", "codePreview", "tryButton", "spinner",
    "format", "rawResponse", "htmlResponse", "responseFrame",
    "headers", "timeout", "jsScript", "returnScriptResult", "customProxy",
    "credits", "device", "errorOn404", "errorOnRedirect",
    "responseFormat", "endpoint", "aiOptions", "fields", "aiFields",
    "textOptions", "textFormat", "returnLinks"
  ]

  static values = {
    currentUserApiKey: String,
    headerRowTemplate: String,
    fieldRowTemplate: String
  }

  connect() {
    // Save the first header row template
    const firstRow = this.headersTarget.querySelector('.header-row')
    if (firstRow) {
      this.headerRowTemplateValue = firstRow.outerHTML
    }

    // Save the first field row template
    const firstFieldRow = this.fieldsTarget.querySelector('.field-row')
    if (firstFieldRow) {
      this.fieldRowTemplateValue = firstFieldRow.outerHTML
    }

    // Check if URL has shared parameters
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('share')) {
      this.loadSharedState(urlParams)
    } else {
      this.loadStateFromStorage()
    }
    
    this.updateRequest()
    this.toggleAiOptions()
    this.toggleTextOptions()
    this.toggleAiFields()
  }

  loadSharedState(params) {
    // Load values from URL parameters
    const fields = {
      apiKey: { target: 'apiKey', default: this.currentUserApiKeyValue || '' },
      url: { target: 'url' },
      js: { target: 'js', type: 'checkbox' },
      jsTimeout: { target: 'jsTimeout' },
      waitFor: { target: 'waitFor' },
      country: { target: 'country' },
      question: { target: 'question' },
      timeout: { target: 'timeout' },
      jsScript: { target: 'jsScript' },
      returnScriptResult: { target: 'returnScriptResult', type: 'checkbox' },
      customProxy: { target: 'customProxy' },
      device: { target: 'device' },
      errorOn404: { target: 'errorOn404', type: 'checkbox' },
      errorOnRedirect: { target: 'errorOnRedirect', type: 'checkbox' },
      textFormat: { target: 'textFormat' },
      returnLinks: { target: 'returnLinks', type: 'checkbox' }
    }

    for (const [key, config] of Object.entries(fields)) {
      const value = params.get(`form[${key}]`)
      if (value !== null) {
        const target = this[`${config.target}Target`]
        if (config.type === 'checkbox') {
          target.checked = value === 'true'
        } else {
          target.value = value
        }
      }
    }

    // Handle radio groups
    const radioGroups = {
      proxy: { targets: 'proxyTargets' },
      endpoint: { targets: 'endpointTargets' },
      format: { targets: 'formatTargets' }
    }

    for (const [key, config] of Object.entries(radioGroups)) {
      const value = params.get(`form[${key}]`)
      if (value) {
        const radio = this[config.targets].find(el => el.value === value)
        if (radio) radio.checked = true
      }
    }

    // Handle headers
    const headers = params.get('form[headers]')
    if (headers) {
      try {
        const parsedHeaders = JSON.parse(headers)
        this.headersTarget.innerHTML = ''
        parsedHeaders.forEach(header => this.addHeaderRow(header.key, header.value))
      } catch (e) {
        console.error('Failed to parse shared headers:', e)
      }
    }

    // Handle fields
    const aiFields = params.get('form[fields]')
    if (aiFields) {
      try {
        const parsedFields = JSON.parse(aiFields)
        this.fieldsTarget.innerHTML = ''
        parsedFields.forEach(field => this.addFieldRow(field.key, field.value))
      } catch (e) {
        console.error('Failed to parse shared fields:', e)
      }
    }

    // Handle language
    const language = params.get('form[language]')
    if (language) {
      const langRadio = this.languageTargets.find(el => el.value === language)
      if (langRadio) langRadio.checked = true
    }
  }

  shareForm() {
    const params = new URLSearchParams()

    // Add share parameter
    params.set('share', 'true')
    
    // Add all form values
    const formValues = {
      url: this.urlTarget.value,
      js: this.jsTarget.checked,
      jsTimeout: this.jsTimeoutTarget.value,
      waitFor: this.waitForTarget.value,
      country: this.countryTarget.value,
      question: this.questionTarget.value,
      timeout: this.timeoutTarget.value,
      jsScript: this.jsScriptTarget.value,
      returnScriptResult: this.returnScriptResultTarget.checked,
      customProxy: this.customProxyTarget.value,
      device: this.deviceTarget.value,
      errorOn404: this.errorOn404Target.checked,
      errorOnRedirect: this.errorOnRedirectTarget.checked,
      textFormat: this.textFormatTarget.value,
      returnLinks: this.returnLinksTarget.checked
    }

    for (const [key, value] of Object.entries(formValues)) {
      if (value !== '' && value !== false) {
        params.set(`form[${key}]`, value)
      }
    }

    // Add radio selections
    const radioSelections = {
      proxy: this.proxyTargets,
      endpoint: this.endpointTargets,
      format: this.formatTargets
    }

    for (const [key, targets] of Object.entries(radioSelections)) {
      const selected = targets.find(el => el.checked)
      if (selected) {
        params.set(`form[${key}]`, selected.value)
      }
    }

    // Add headers
    const headers = []
    this.headersTarget.querySelectorAll('.header-row').forEach(row => {
      const inputs = row.querySelectorAll('input')
      if (inputs[0].value && inputs[1].value) {
        headers.push({
          key: inputs[0].value,
          value: inputs[1].value
        })
      }
    })
    if (headers.length > 0) {
      params.set('form[headers]', JSON.stringify(headers))
    }

    // Add fields
    const fields = []
    this.fieldsTarget.querySelectorAll('.field-row').forEach(row => {
      const inputs = row.querySelectorAll('input')
      if (inputs[0].value && inputs[1].value) {
        fields.push({
          key: inputs[0].value,
          value: inputs[1].value
        })
      }
    })
    if (fields.length > 0) {
      params.set('form[fields]', JSON.stringify(fields))
    }

    // Add selected language
    const selectedLanguage = this.languageTargets.find(el => el.checked)
    if (selectedLanguage) {
      params.set('form[language]', selectedLanguage.value)
    }

    // Create shareable URL
    const shareableUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`
    
    // Copy to clipboard
    const button = event.currentTarget
    const originalText = button.innerHTML
    navigator.clipboard.writeText(shareableUrl)
      .then(() => {
        button.innerHTML = 'Copied!'
        setTimeout(() => {
          button.innerHTML = originalText
        }, 2000)
      })
      .catch(err => console.error('Failed to copy URL:', err))
  }

  loadStateFromStorage() {
    // Define the fields to load from localStorage with their default values
    const fields = {
      apiKey: { target: 'apiKey', default: this.currentUserApiKeyValue || '' },
      url: { target: 'url', default: 'https://example.com' },
      js: { target: 'js', default: true, type: 'checkbox' },
      jsTimeout: { target: 'jsTimeout', default: '2000' },
      waitFor: { target: 'waitFor', default: '' },
      country: { target: 'country', default: 'us' },
      question: { target: 'question', default: '' },
      timeout: { target: 'timeout', default: '10000' },
      jsScript: { target: 'jsScript', default: '' },
      returnScriptResult: { target: 'returnScriptResult', default: false, type: 'checkbox' },
      customProxy: { target: 'customProxy', default: '' },
      device: { target: 'device', default: 'desktop' },
      errorOn404: { target: 'errorOn404', default: false, type: 'checkbox' },
      errorOnRedirect: { target: 'errorOnRedirect', default: false, type: 'checkbox' },
      format: { target: 'format', default: 'text' },
      textFormat: { target: 'textFormat', default: 'plain' },
      returnLinks: { target: 'returnLinks', default: false, type: 'checkbox' }
    }

    // Load values for each field
    for (const [key, config] of Object.entries(fields)) {
      const storedValue = localStorage.getItem(key)
      const target = this[`${config.target}Target`]

      if (config.type === 'checkbox') {
        target.checked = storedValue === null ? config.default : storedValue === 'true'
      } else {
        target.value = storedValue || config.default
      }
    }

    // Handle radio button groups
    const radioGroups = {
      proxy: { targets: 'proxyTargets', default: 'datacenter' },
      endpoint: { targets: 'endpointTargets', default: 'html' },
      format: { targets: 'formatTargets', default: 'text' }
    }

    for (const [key, config] of Object.entries(radioGroups)) {
      const savedValue = localStorage.getItem(key) || config.default
      this[config.targets].find(el => el.value === savedValue).checked = true
    }

    // Handle headers
    const savedHeaders = JSON.parse(localStorage.getItem('headers') || '[]')
    this.headersTarget.innerHTML = ''
    
    if (savedHeaders.length > 0) {
      savedHeaders.forEach(header => this.addHeaderRow(header.key, header.value))
    } else {
      this.addHeaderRow('', '')
    }

    // Handle fields
    const savedFields = JSON.parse(localStorage.getItem('fields') || '[]')
    this.fieldsTarget.innerHTML = ''
    
    if (savedFields.length > 0) {
      savedFields.forEach(field => this.addFieldRow(field.key, field.value))
    } else {
      this.addFieldRow('', '')
    }

    // Handle language selection
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      this.languageTargets.find(el => el.value === savedLanguage).checked = true
    }
  }

  saveStateToStorage() {
    // Don't save if share=true is in URL
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('share')) {
      return
    }

    // Save direct values
    const directValues = {
      'apiKey': this.apiKeyTarget.value,
      'url': this.urlTarget.value, 
      'js': this.jsTarget.checked,
      'jsTimeout': this.jsTimeoutTarget.value,
      'waitFor': this.waitForTarget.value,
      'country': this.countryTarget.value,
      'question': this.questionTarget.value,
      'timeout': this.timeoutTarget.value,
      'jsScript': this.jsScriptTarget.value,
      'returnScriptResult': this.returnScriptResultTarget.checked,
      'customProxy': this.customProxyTarget.value,
      'device': this.deviceTarget.value,
      'errorOn404': this.errorOn404Target.checked,
      'errorOnRedirect': this.errorOnRedirectTarget.checked,
      'textFormat': this.textFormatTarget.value,
      'returnLinks': this.returnLinksTarget.checked
    }

    for (const [key, value] of Object.entries(directValues)) {
      localStorage.setItem(key, value)
    }

    // Save selected radio values
    const radioSelections = {
      'proxy': this.proxyTargets,
      'endpoint': this.endpointTargets,
      'format': this.formatTargets
    }

    for (const [key, targets] of Object.entries(radioSelections)) {
      const selected = targets.find(el => el.checked)
      if (selected) {
        localStorage.setItem(key, selected.value)
      }
    }

    // Save headers
    const headers = []
    this.headersTarget.querySelectorAll('.header-row').forEach(row => {
      const inputs = row.querySelectorAll('input')
      if (inputs[0].value && inputs[1].value) {
        headers.push({
          key: inputs[0].value,
          value: inputs[1].value
        })
      }
    })
    localStorage.setItem('headers', JSON.stringify(headers))

    // Save fields
    const fields = []
    this.fieldsTarget.querySelectorAll('.field-row').forEach(row => {
      const inputs = row.querySelectorAll('input')
      if (inputs[0].value && inputs[1].value) {
        fields.push({
          key: inputs[0].value,
          value: inputs[1].value
        })
      }
    })
    localStorage.setItem('fields', JSON.stringify(fields))

    // Save selected language
    const selectedLanguage = this.languageTargets.find(el => el.checked)
    if (selectedLanguage) {
      localStorage.setItem('language', selectedLanguage.value)
    }
  }

  resetForm() {
    const keysToRemove = [
      'apiKey', 'url', 'js', 'jsTimeout', 'waitFor', 'proxy', 'country', 
      'question', 'timeout', 'jsScript', 'returnScriptResult', 'customProxy',
      'headers', 'fields', 'language', 'device', 'errorOn404',
      'errorOnRedirect', 'format', 'endpoint', 'textFormat', 'returnLinks'
    ]
    
    for (const key of keysToRemove) {
      localStorage.removeItem(key)
    }
    
    this.loadStateFromStorage()
    
    // Reset headers
    this.headersTarget.innerHTML = ''
    this.addHeaderRow('', '')

    // Reset fields
    this.fieldsTarget.innerHTML = ''
    this.addFieldRow('', '')
    
    this.updateRequest()
  }

  updateRequest() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    let endpoint = selectedEndpoint ? selectedEndpoint.value : 'html'
    let params = this.buildParams()
    const queryString = new URLSearchParams(params).toString()
    this.resultUrl = `https://api.webscraping.ai/${endpoint}?${queryString}`
    
    this.updateCodePreview(endpoint, params)
    this.updateCredits()
    this.saveStateToStorage()
  }

  updateCredits() {
    let credits = 5 // Default: js=true + proxy=datacenter
    
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isJs = this.jsTarget.checked
    const isResidential = this.proxyTargets.find(el => el.checked).value === 'residential'

    if (!isJs && !isResidential) credits = 1
    else if (!isJs && isResidential) credits = 10
    else if (isJs && !isResidential) credits = 5
    else if (isJs && isResidential) credits = 25

    if (selectedEndpoint && selectedEndpoint.value === 'ai/question') {
      credits += 5
    }

    this.creditsTarget.textContent = credits
  }

  toggleAiOptions() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isAiEndpoint = selectedEndpoint && selectedEndpoint.value === 'ai/question'
    this.aiOptionsTargets.forEach(target => {
      target.classList.toggle('hidden', !isAiEndpoint)
    })
    this.updateRequest()
  }

  toggleTextOptions() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isTextEndpoint = selectedEndpoint && selectedEndpoint.value === 'text'
    this.textOptionsTargets.forEach(target => {
      target.classList.toggle('hidden', !isTextEndpoint)
    })
    this.updateRequest()
  }

  toggleAiFields() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isAiEndpoint = selectedEndpoint && selectedEndpoint.value === 'ai/fields'
    this.aiFieldsTargets.forEach(target => {
      target.classList.toggle('hidden', !isAiEndpoint)
    })
    this.updateRequest()
  }

  toggleJsAccordion(event) {
    const button = event.currentTarget
    const icon = button.querySelector('[data-js-accordion-icon]')
    const content = button.nextElementSibling
    
    // Toggle content visibility
    content.classList.toggle('hidden')
    
    // Rotate icon
    if (content.classList.contains('hidden')) {
      icon.style.transform = 'rotate(0deg)'
    } else {
      icon.style.transform = 'rotate(180deg)'
    }
  }

  buildParams() {
    const params = {
      api_key: this.apiKeyTarget.value,
      url: this.urlTarget.value,
      timeout: this.timeoutTarget.value
    }

    // Build headers from input fields
    const headers = {}
    const headerRows = this.headersTarget.querySelectorAll('.header-row')
    headerRows.forEach(row => {
      const key = row.querySelector('input[placeholder="Key"]').value
      const value = row.querySelector('input[placeholder="Value"]').value
      if (key && value) {
        headers[key] = value
      }
    })
    if (Object.keys(headers).length > 0) {
      params.headers = JSON.stringify(headers)
    }

    if (this.jsTarget.checked) {
      params.js = "true"
      params.js_timeout = this.jsTimeoutTarget.value
      
      if (this.waitForTarget.value) {
        params.wait_for = this.waitForTarget.value
      }

      if (this.jsScriptTarget.value) {
        params.js_script = this.jsScriptTarget.value
      }

      if (this.returnScriptResultTarget.checked) {
        params.return_script_result = "true"
      }
    }

    if (this.customProxyTarget.value) {
      params.customProxy = this.customProxyTarget.value
    } else {
      const selectedProxy = this.proxyTargets.find(el => el.checked)
      if (selectedProxy && selectedProxy.value !== "datacenter") {
        params.proxy = selectedProxy.value
      }
    }

    if (this.countryTarget.value !== "us") {
      params.country = this.countryTarget.value
    }

    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    if (selectedEndpoint && selectedEndpoint.value === 'ai/question') {
      params.question = this.questionTarget.value
    }

    if (selectedEndpoint && selectedEndpoint.value === 'ai/fields') {
      const fields = {}
      const fieldRows = this.fieldsTarget.querySelectorAll('.field-row')
      fieldRows.forEach(row => {
        const key = row.querySelector('input[placeholder="product_description"]').value
        const value = row.querySelector('input[placeholder="Description of the product on the page"]').value
        if (key && value) {
          fields[key] = value
        }
      })
      if (Object.keys(fields).length > 0) {
        params.fields = JSON.stringify(fields)
      }
    }

    if (selectedEndpoint && selectedEndpoint.value === 'text') {
      if (this.textFormatTarget.value !== "plain") {
        params.text_format = this.textFormatTarget.value
      }
      if (this.returnLinksTarget.checked) {
        params.return_links = "true"
      }
    }

    if (this.deviceTarget.value !== "desktop") {
      params.device = this.deviceTarget.value
    }

    if (this.errorOn404Target.checked) {
      params.error_on_404 = "true"
    }

    if (this.errorOnRedirectTarget.checked) {
      params.error_on_redirect = "true"
    }

    const selectedFormat = this.formatTargets.find(el => el.checked)
    if (selectedFormat && selectedFormat.value !== "text") {
      params.format = selectedFormat.value
    }

    return params
  }

  addHeaderRow(key = '', value = '') {
    const template = document.createElement('div')
    template.innerHTML = this.headerRowTemplateValue
    const newRow = template.firstElementChild
    
    const inputs = newRow.querySelectorAll('input')
    inputs[0].value = key
    inputs[1].value = value
    
    this.headersTarget.appendChild(newRow)
  }

  addHeader() {
    this.addHeaderRow()
    this.updateRequest()
  }

  removeHeader(event) {
    const headerRows = this.headersTarget.querySelectorAll('.header-row')
    if (headerRows.length > 1) {
      event.target.closest('.header-row').remove()
      this.updateRequest()
    } else {
      // Clear inputs if it's the last row
      event.target.closest('.header-row').querySelectorAll('input').forEach(input => input.value = '')
      this.updateRequest()
    }
  }

  addFieldRow(key = '', value = '') {
    const template = document.createElement('div')
    template.innerHTML = this.fieldRowTemplateValue
    const newRow = template.firstElementChild
    
    const inputs = newRow.querySelectorAll('input')
    inputs[0].value = key
    inputs[1].value = value
    
    this.fieldsTarget.appendChild(newRow)
  }

  addField() {
    this.addFieldRow()
    this.updateRequest()
  }

  removeField(event) {
    const fieldRows = this.fieldsTarget.querySelectorAll('.field-row')
    if (fieldRows.length > 1) {
      event.target.closest('.field-row').remove()
      this.updateRequest()
    } else {
      // Clear inputs if it's the last row
      event.target.closest('.field-row').querySelectorAll('input').forEach(input => input.value = '')
      this.updateRequest()
    }
  }

  updateCodePreview(endpoint, params) {
    const selectedLanguage = this.languageTargets.find(el => el.checked).value
    const baseUrl = 'https://api.webscraping.ai/' + endpoint
    let code = ''

    switch(selectedLanguage) {
      case 'curl':
        code = `curl "${baseUrl}?${new URLSearchParams(params)}"`
        break
      case 'nodejs':
        code = `const https = require('https');

const params = ${JSON.stringify(params, null, 2)};
const queryString = new URLSearchParams(params).toString();
const url = '${baseUrl}?' + queryString;

https.get(url, (resp) => {
  let data = '';
  resp.on('data', (chunk) => { data += chunk; });
  resp.on('end', () => {
    console.log(data);
  });
}).on("error", (err) => {
  console.log("Error: " + err.message);
});`
        break
      case 'python':
        code = `import requests

params = ${JSON.stringify(params, null, 2)}
response = requests.get('${baseUrl}', params=params)
print(response.text)`
        break
      case 'ruby':
        code = `require 'net/http'
require 'uri'

params = ${JSON.stringify(params, null, 2)}
uri = URI('${baseUrl}')
uri.query = URI.encode_www_form(params)

response = Net::HTTP.get_response(uri)
puts response.body`
        break
      case 'php':
        code = `<?php
$params = ${JSON.stringify(params, null, 2)};
$query = http_build_query($params);
$url = '${baseUrl}?' . $query;

$response = file_get_contents($url);
echo $response;
?>`
        break
    }

    // Update code preview with language class
    this.codePreviewTarget.textContent = code
    
    // Map language names to Prism's language identifiers
    const languageMap = {
      'nodejs': 'javascript',
      'python': 'python',
      'ruby': 'ruby', 
      'php': 'php',
      'curl': 'bash'
    }
    const prismLanguage = languageMap[selectedLanguage] || selectedLanguage
    this.codePreviewTarget.className = `language-${prismLanguage}`
    
    // Trigger syntax highlighting if Prism is available
    if (typeof Prism !== 'undefined') {
      Prism.highlightElement(this.codePreviewTarget)
    }
  }

  async executeRequest() {
    try {
      // Show spinner and disable button
      this.spinnerTarget.classList.remove("hidden")
      this.tryButtonTarget.disabled = true

      const response = await fetch(this.resultUrl)
      const data = await response.text()
      
      this.responseTarget.textContent = data
      this.responseAreaTarget.style.display = "block"

      // Handle HTML preview if selected
      const selectedFormat = this.responseFormatTargets.find(el => el.checked).value
      if (selectedFormat === 'html') {
        this.rawResponseTarget.classList.add('hidden')
        this.htmlResponseTarget.classList.remove('hidden')
        this.responseFrameTarget.srcdoc = data
      } else {
        this.rawResponseTarget.classList.remove('hidden')
        this.htmlResponseTarget.classList.add('hidden')
      }

    } catch (error) {
      this.responseTarget.textContent = `Error: ${error.message}`
      this.responseAreaTarget.style.display = "block"
      this.rawResponseTarget.classList.remove('hidden')
      this.htmlResponseTarget.classList.add('hidden')
    } finally {
      // Hide spinner and enable button
      this.spinnerTarget.classList.add("hidden") 
      this.tryButtonTarget.disabled = false
    }
  }

  toggleResponseFormat() {
    const selectedFormat = this.responseFormatTargets.find(el => el.checked).value
    if (selectedFormat === 'html') {
      this.rawResponseTarget.classList.add('hidden')
      this.htmlResponseTarget.classList.remove('hidden')
      if (this.responseTarget.textContent) {
        this.responseFrameTarget.srcdoc = this.responseTarget.textContent
      }
    } else {
      this.rawResponseTarget.classList.remove('hidden')
      this.htmlResponseTarget.classList.add('hidden')
    }
    this.saveStateToStorage()
  }

  copyCode() {
    const code = this.codePreviewTarget.textContent
    navigator.clipboard.writeText(code)
  }
}
